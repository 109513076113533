//@ts-nocheck
import { useLayoutEffect, useRef } from "react";
import { useTranslation } from "react-i18next";
import { useMutation, useQueries, useQuery, useQueryClient, UseQueryOptions } from "react-query";
import { CaretRightOutlined, CloseOutlined, DeleteOutlined, EyeOutlined } from "@ant-design/icons";
import { Button, Space, Tooltip, Typography } from "antd";
import { ColumnsType } from "antd/es/table";
import { AxiosError } from "axios";
import dayjs from "dayjs";
import i18next from "i18next";
import {
  deleteRT,
  executeSchedule,
  getNextReference,
  getRecurringTransactionSchedules,
  getRTPermissions,
  getRTRecords,
  getSchedules,
  getTransaction,
  updateStatus,
} from "api/recurring_transactions";
import { formatAmountWithCurrency } from "helpers/app";
import { CDStatuses, RTDetailsStatuses } from "types/app";
import {
  DocumentTypes,
  QueryOpts,
  RTDocumentsTypes,
  RTIndexResponse,
  RTSchedules,
  ScheduleType,
  SelectedDocument,
} from "types/recurring_transactions";
import { useStatusMap } from "./status";

const TABLE_HEADERS = "recurring_transactions.table_headers";
const ACTIONS = "recurring_transactions.actions";

export const useRTPermissions = () => {
  const { data, error, isLoading } = useQuery({
    queryKey: ["RTPermissions"],
    queryFn: getRTPermissions,
  });

  useLayoutEffect(() => {
    if (error) {
      window.location.replace("/tenant/dashboards");
    }
  }, [error]);

  return { permissions: data, isPermissionsLoading: isLoading };
};

export const useSchedules = (
  transactionId: number | undefined,
  queryOpts: {
    [ScheduleType.PASSED]: QueryOpts;
    [ScheduleType.UPCOMMING]: QueryOpts;
  }
) => {
  const queryClient = useQueryClient();

  const [passedSchedulesQuery, upcomingSchedulesQuery] = useQueries(
    [ScheduleType.PASSED, ScheduleType.UPCOMMING].map((type) => ({
      queryKey: ["Schedules", type, queryOpts[type]],
      queryFn: () =>
        getSchedules(
          transactionId!,
          new URLSearchParams({
            type,
            page: queryOpts[type].page ?? "1",
            per_page: queryOpts[type].per_page ?? "15",
          })
        ),
      enabled: Boolean(transactionId) && (queryOpts[type].enabled ?? true),
    }))
  );

  const refetchSchedules = async () => {
    await Promise.all([
      passedSchedulesQuery.refetch(),
      upcomingSchedulesQuery.refetch(),
      queryClient.invalidateQueries({
        queryKey: ["transaction", transactionId],
      }),
    ]);
  };

  const isRefetchingSchedules =
    passedSchedulesQuery.isRefetching && passedSchedulesQuery.isRefetching; 

  return {
    passedSchedulesQuery,
    upcomingSchedulesQuery,
    refetchSchedules,
    isRefetchingSchedules
  };
};

export const useTransaction = (
  id: number | null | undefined,
  querySearch?: URLSearchParams
) => {
  const query = useQuery({
    queryKey: ["transaction", id, querySearch?.toString()],
    queryFn: () => getTransaction(id, querySearch),
    enabled: Boolean(id),
  });

  return query;
}

interface DocumentsColumnsProps {
  handleDeleteDocument: (id: number) => void;
}

export const useDocumentsColumns = ({ handleDeleteDocument }: DocumentsColumnsProps) => {
  const { t } = useTranslation();
  const statusMap = useStatusMap();

  const columns: ColumnsType<SelectedDocument> = [
    {
      title: t(`${TABLE_HEADERS}.reference`),
      dataIndex: "reference",
      key: "reference",
    },
    {
      title: t(`${TABLE_HEADERS}.amount`),
      dataIndex: "amount",
      render(value) {
        return value && formatAmountWithCurrency(value);
      },
      key: "amount",
    },
    {
      title: t(`${TABLE_HEADERS}.date`),
      dataIndex: "date",
      key: "date",
    },
    {
      title: t(`${TABLE_HEADERS}.document_type`),
      dataIndex: "type",
      key: "type",
      render: (type: DocumentTypes) => type.slice(0, -1),
    },
    {
      title: t(`${TABLE_HEADERS}.status`),
      dataIndex: "status",
      key: "status",
      render: (status: CDStatuses) => {
        const statusData = statusMap[status];
        return (
          statusData && (
            <Typography.Text style={{ color: statusData.color }}>
              {statusData.label}
            </Typography.Text>
          )
        );
      },
    },
    {
      title: t(`${TABLE_HEADERS}.actions`),
      dataIndex: "id",
      key: "id",
      render: (id: number, record: SelectedDocument) => {
        let documentType: string = "";
        if (record.type === "SimpleBills") {
          documentType = "simple_bills";
        } else if (record.type === "Bills" || record.type === "Invoices") {
          documentType = record.type.toLowerCase();
        }
        return (
          <Space>
            <Button icon={<EyeOutlined />} href={`/tenant/${documentType}/${id}`} target="_blank" />
            <Button icon={<DeleteOutlined />} onClick={() => handleDeleteDocument(id)} />
          </Space>
        );
      },
    },
  ];
  return columns;
};

interface TransactionsColumnsProps {
  handleStartProcess: (id: number) => void;
  openSkipProcessModal: (id: number) => void;
  isRunProcessing: boolean;
}

export const useTransactionsColumns = ({
  handleStartProcess,
  openSkipProcessModal,
  isRunProcessing,
}: TransactionsColumnsProps) => {
  const { t } = useTranslation();
  const statuses = useStatusMap();
  const firstSchedule = useRef<string>();
  const isRunning = useRef<{ [key: number]: boolean }>({});

  const columns: ColumnsType<RTSchedules> = [
    {
      title: t(`${TABLE_HEADERS}.reference`),
      dataIndex: "reference",
      key: "reference",
      render: (reference: string, record: RTSchedules) => {
        return record.original_documents ? (
          <Tooltip placement="right" title={t(`${TABLE_HEADERS}.reference_hover`)}>
            <Typography.Link
              href={`/tenant/recurring_transactions/${record.recurring_transaction_id}`}
              target="_blank"
            >
              {reference}
            </Typography.Link>
          </Tooltip>
        ) : (
          reference
        );
      },
    },
    {
      title: t(`${TABLE_HEADERS}.name`),
      dataIndex: "name",
      key: "name",
      render: (_, record: RTSchedules) =>
        i18next.language === "ar" ? record?.ar_name : record?.en_name,
    },
    {
      title: t(`${TABLE_HEADERS}.created_date`),
      dataIndex: "created_at",
      key: "created_at",
      render: (created_at: string, record: RTSchedules) =>
        record.original_documents && dayjs(created_at).format("DD-MM-YYYY"),
    },
    {
      title: t(`${TABLE_HEADERS}.date_next_run`),
      dataIndex: "scheduled_run_date",
      key: "scheduled_run_date",
      render: (scheduled_run_date: string, record: RTSchedules) =>
        record.original_documents && dayjs(scheduled_run_date).format("DD-MM-YYYY"),
    },
    {
      title: t(`${TABLE_HEADERS}.status`),
      dataIndex: "status",
      key: "status",
      render: (status: RTDetailsStatuses) => {
        const normalizedStatus = statuses._normalize(status);

        return (
          normalizedStatus && (
            <Typography.Text
              style={{ color: statuses[normalizedStatus as RTDetailsStatuses].color }}
            >
              {statuses[normalizedStatus as RTDetailsStatuses].label}
            </Typography.Text>
          )
        );
      },
    },
    {
      title: t(`${TABLE_HEADERS}.actions`),
      dataIndex: "id",
      key: "id",
      render: (id: number, record: RTSchedules, index) => {
        if (record.original_documents) {
          const recurringRef: string = record.reference.split("-")[0];
          let disabledAction: boolean;

          if (recurringRef === firstSchedule.current && index != 0 ) {
            disabledAction = true;
          } else {
            disabledAction = false;
            firstSchedule.current = recurringRef;
          }

          return (
            <Space>
              <Tooltip title={t(`${ACTIONS}.start_process`)}>
                <Button
                  icon={<CaretRightOutlined />}
                  onClick={() => {
                    isRunning.current = { [id]: true };
                    try {
                      handleStartProcess(id);
                    } catch (error) {
                      isRunning.current = { [id]: false };
                    }
                  }}
                  disabled={disabledAction}
                  loading={isRunProcessing && isRunning.current[id]}
                />
              </Tooltip>
              <Tooltip title={t(`${ACTIONS}.skip_process`)}>
                <Button
                  icon={<CloseOutlined />}
                  onClick={() => openSkipProcessModal(id)}
                  disabled={disabledAction}
                />
              </Tooltip>
            </Space>
          );
        }
      },
    },
  ];
  return { columns };
};

export const useNextReference = () => {
  const { data, refetch } = useQuery({
    queryKey: "recurringTransactionNextReference",
    queryFn: () => getNextReference(),
  });

  return { nextReference: data?.next_reference, refetch };
};

export const useRecurringTransactionSchedules = (
  currentPage: number,
  pageSize: number,
  documentType?: RTDocumentsTypes | null
) => {
  const { data, isLoading } = useQuery({
    queryKey: ["recurringTransactionSchedules", currentPage, pageSize, documentType],
    queryFn: () => getRecurringTransactionSchedules(currentPage, pageSize, documentType),
  });

  return { data, isSchedulesLoading: isLoading };
};

export const useRTRecords = ({
  querySearch,
  ...queryOptions
}: {
  querySearch: string;
} & UseQueryOptions<RTIndexResponse, AxiosError>) => {
  const query = useQuery({
    queryFn: ()=>getRTRecords(querySearch),
    ...queryOptions,
  });

  return query;
};


export const useToggleRTStatus = () => {
  const queryClient = useQueryClient();

  const query = useMutation({
    mutationKey: "RTToggleStatus",
    mutationFn: updateStatus,
    async onSuccess() {
      await queryClient.invalidateQueries({
        queryKey: ["RTIndex"],
      });
    },
  });
  return {
    ...query,
    data: query.data?.data,
  };
};

export const useDeleteRT = () => {
  const queryClient = useQueryClient();

  const query = useMutation({
    mutationKey: "RTDelete",
    mutationFn: deleteRT,
    async onSuccess() {
      await queryClient.invalidateQueries({
        queryKey: ["RTIndex"],
      });
    },
  });
  return {
    ...query,
    data: query.data?.data,
  };
};

export const useExecuteSchedule = () => {
  const queryClient = useQueryClient();

  const query = useMutation({
    mutationKey: "RTExecuteSchedule",
    mutationFn({ id, operation }: { id: number; operation: "run" | "skip" }) {
      return executeSchedule(id, operation);
    },
    async onSuccess() {
      await Promise.all([
        queryClient.invalidateQueries({
          queryKey: ["Schedules"],
        }),
        queryClient.invalidateQueries({
          queryKey: ["transaction"],
        }),
        queryClient.invalidateQueries({
          queryKey: ["recurringTransactionSchedules"],
        }),
      ]);
    },
    async onError() {
      await Promise.all([
        queryClient.invalidateQueries({
          queryKey: ["Schedules"],
        }),
        queryClient.invalidateQueries({
          queryKey: ["transaction"],
        }),
        queryClient.invalidateQueries({
          queryKey: ["recurringTransactionSchedules"],
        }),
      ]);
    },
  });
  return query;
};
