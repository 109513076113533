//@ts-nocheck
import React, { useState, useEffect } from "react";
import { Table, Button, Space, Flex, theme, message, Modal } from "antd";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { getDateRanges, getSingleBudget, postBudgetDetails } from "api/budgets";
import { useTranslation } from "react-i18next";
import i18next from "i18next";
import moment from "moment";
import BudgetDetailsHeaderSection from "./BudgetDetailsHeaderSection";
import { useBudgetDetailsColumns, useResetRowDataModal } from "hooks/budgets";
import { calculateBudgetAmount, getBudgetIdFromUrl, resetDateToZero ,updateParent} from "helpers/budgets";
import BrakeDownModal from "./BreakDownModal";
import { BudgetContextType, BudgetRow ,BudgetDetailsT} from "types/budget";
const { useToken } = theme;
const id = getBudgetIdFromUrl();
export const BudgetContext = React.createContext<BudgetContextType>(null);
const BudgetDetails = ({permissions}) => {
  const queryClient = useQueryClient();
  const { t } = useTranslation();
  const { token } = useToken();
  const [cycle, setCycle] = useState({ no: 12, type: "monthly" });
  const [formData, setFormData] = useState({});
  const [modalOpen, setModalOpen] = useState(false);
  const [selectedRow, setSelectRow] = useState<BudgetRow | null>(null);
  const [originalCycle, setOriginalCycle] = useState(null);
  const [initialRowData, setInitialRowData] = useState({});
  const [isSaveButtonDisabled, setIsSaveButtonDisabled] = useState(true);
  const queryParams = new URLSearchParams(window.location.search);
  const type = queryParams.get("type");
  const [expandedKeys, setExpandedKeys] = useState([]);

  if (i18next.language === "ar") {
    moment.locale("ar");
  }

  const { data: dateRanges } = useQuery({
    queryKey: ["date_ranges", id, cycle],
    queryFn: () => getDateRanges(id, cycle.type),
  });

  const { data, isLoading } = useQuery<BudgetDetailsT>({
    queryKey: ["budget_details", id],
    queryFn: () => getSingleBudget(id),
    onSuccess: (data) => {
      setCycle({ no: data?.cycle === "quarterly" ? 4 : 12, type: data?.cycle });
      setSelectRow({
        ...selectedRow,
        budgeting_value_id: data.budgetings.find(
          (item) => item.id === selectedRow.id
        ).budgeting_values.id,
      });
    },
  });

  console.log(selectedRow);
  const updateBudgetDetails = useMutation({
    mutationFn: (data) => postBudgetDetails(data, id),
    onSuccess() {
      message.success(t("budgets.show.budget_details.saved"));
      queryClient.invalidateQueries({ queryKey: ["budget_details"] });
      setIsSaveButtonDisabled(true);
    },
  });

  const updateParentNodes = (formData) => {
    Object.keys(formData).forEach((recordId) => {
      const record = formData[recordId];
      if (record.parentId) {
        updateParent(formData, record.parentId, cycle);
      }
    });
  };

  useEffect(() => {
    if (data && data.cycle) {
      setOriginalCycle(data.cycle);
    }
  }, [data]);


  const getAllKeysRecursively = (row) => {
    if (!row) return [];

    const rowKey = row.id;
    const childKeys = row.children
      ? row.children.flatMap(getAllKeysRecursively)
      : [];

    return [rowKey, ...childKeys];
  };
  useEffect(() => {
    const initialData = {};
    const addInitialData = (data, parentId = null) => {
      data.forEach((account) => {
        const values = account?.budgeting_values?.values || [];
        initialData[account.id] = {
          ...account,
          ...Object.fromEntries(
            Array.from({ length: cycle.no }, (_, i) => [
              i + 1,
              parseFloat(values[i] || 0),
            ])
          ),
          budget_amount: calculateBudgetAmount(values),
          children: account.children.map((child) => child?.id),
          parentId,
          budgeting_value_id: account.budgeting_values?.id,
        };
        if (account.children.length) {
          addInitialData(account.children, account?.id);
        }
      });
    };
    if (data && dateRanges && cycle.type === data?.cycle) {
      addInitialData(data?.budgetings);
      updateParentNodes(initialData);
      setFormData(initialData);
      setInitialRowData(initialData);

      const firstRow = data?.budgetings.sort((a, b) => {
        const codeA = a.code;
        const codeB = b.code;
        return codeA.localeCompare(codeB);
      })[0]
      if (firstRow) {
        const expandedKeys = getAllKeysRecursively(firstRow);
        setExpandedKeys(expandedKeys);
      }
    } else {
      const resetData = resetDateToZero(formData, cycle);
      setFormData(resetData);
      setInitialRowData(resetData);
    }
  }, [data, dateRanges, cycle]);

  const handleReset = (recordId = null) => {
    setFormData((prevFormData) => {
      let newFormData = { ...prevFormData };
      const resetRecord = (id) => {
        if (newFormData[id] && initialRowData[id]) {
          if (cycle.type !== originalCycle) {
            newFormData[id] = {
              ...newFormData[id],
              budget_amount: 0,
              ...Object.fromEntries(
                Array.from({ length: cycle.no }, (_, index) => [index + 1, 0])
              ),
            };
      } else {
            newFormData[id] = {
              ...newFormData[id],
              budget_amount: initialRowData[id].budget_amount,
              ...Object.fromEntries(
                Array.from({ length: cycle.no }, (_, index) => [
                  index + 1,
                  initialRowData[id][index + 1] || 0
                ])
              ),
            };
          }
          newFormData[id].children.forEach((childId) => resetRecord(childId));
  
          updateParent(newFormData, newFormData[id].parentId,cycle);      
          }
        };   
      if (recordId) {
        resetRecord(recordId);
        const hasUnsavedChanges = Object.keys(newFormData).some((key) => {
          return (
            JSON.stringify(newFormData[key]) !==
            JSON.stringify(initialRowData[key])
          );
        });  
        setIsSaveButtonDisabled(!hasUnsavedChanges);
        } else {
          Object.keys(newFormData).forEach(key => {
            resetRecord(key);
          });
          
          Object.keys(newFormData).forEach(key => {
            if (!newFormData[key].parentId) {
              updateParent(newFormData, key, cycle);
            }
          });
          setIsSaveButtonDisabled(true);
          }
      return newFormData;
    });
  };
  const { showResetRowModal, resetRowModal } =
    useResetRowDataModal(handleReset);

  const handleInputChange = (e, recordId, field) => {
    e.preventDefault();
    let value = e.target.value;
    value = value.replace(/^0+(?=\d)/, ''); 
    const isValidNumber = /^[0-9]*$/.test(value);
    const totalDigits = value.length;

    if (isValidNumber && totalDigits <= 10) {
      setFormData((prevFormData) => {
        const updatedRecord = {
          ...prevFormData[recordId],
          [field]: value === "" ? 0 : value, 
        };
        const newFormData = {
          ...prevFormData,
          [recordId]: updatedRecord,
        };
        setIsSaveButtonDisabled(false);

        return newFormData;
      });
    }
  };

  const handleBlur = (e, recordId, field) => {
    handleInputChange(e, recordId, field);
  
    setFormData((prevFormData) => {
      const updatedRecord = { ...prevFormData[recordId] };
  
          const numberOfFields = dateRanges.length === 4 ? 4 : 12;
          const ranges = Array.from(
            { length: numberOfFields },
            (_, index) => index + 1
          );
          updatedRecord.budget_amount = ranges.reduce((sum, key) => {
            const fieldValue = parseFloat(updatedRecord[key]) || 0;
            return sum + fieldValue;
          }, 0);

        const newFormData = {
          ...prevFormData,
          [recordId]: updatedRecord,
        };

        const finalFormData = updateParent(
          newFormData,
          newFormData[recordId].parentId,cycle
        );
        setIsSaveButtonDisabled(false);
        return finalFormData || newFormData;
      });
  };

  const handleSubmit = () => {
    const convertObjectToArray = (obj) => {
      return Object.entries(obj).map(([key, value]) => {
        const valuesArray = Array.from({ length: cycle.no }, (_, index) => {
          const valueNumber = parseFloat(value[index + 1]) || 0;
          return valueNumber;
        });
        return {
          budgeting_id: key,
          values: valuesArray,
        };
      });
    };

    const formDataResult = new FormData();

    const result = convertObjectToArray(formData);

    result.forEach((el, i) => {
      formDataResult.append(`budget[budgeting_values][]`, JSON.stringify(el));
    });

    formDataResult.append("budget[cycle]", cycle.type);

    updateBudgetDetails.mutate(formDataResult as any, {
      onSuccess: () => {
        window.location.href = "/tenant/budgets";
      },
    });
  };

  const renderTable = (data) => {
    if (!data) return [];
    const sortedData = [...data].sort((a, b) => {
      const codeA = a.code;
      const codeB = b.code;
      return codeA < codeB ? -1 : codeA > codeB ? 1 : 0;
    });

    return sortedData.map((item) => ({
      key: item?.id,
      id: item?.id,
      code: item.code,
      name: i18next.language === "en" ? item.en_name : item.ar_name,
      budget_amount: formData[item?.id]?.budget_amount || 0,
      budgeting_value_id: item.budgeting_values?.id,
      ...Object.fromEntries(
        Array.from({ length: cycle.no }, (_, index) => [
          index + 1,
          formData[item?.id]?.[index + 1] || 0,
        ])
      ),
      children: item.children ? renderTable(item.children) : undefined,
    }));
  };

  const dataSource = renderTable(data?.budgetings);

  const rowExpandable = (record) => {
    return record.children && record.children.length > 0;
  };

  const handleExpand = (expanded, record) => {
    setExpandedKeys((prevExpandedKeys) => {
      if (expanded) {
        return [...prevExpandedKeys, record.key];
      } else {
        return prevExpandedKeys.filter((key) => key !== record.key);
      }
    });
  };

  const customExpandIcon = ({ expanded, onExpand, record }) => {
    if (record.children && record.children.length > 0) {
      return (
        <i
          style={{ border: `1px solid ${token.colorPrimary}` }}
          aria-label="icon: caret-right"
          className={`ant-table-row-expand-icon ant-table-row-expand-icon-${
            expanded ? "expanded" : "collapsed"
          }`}
          onClick={(e) => onExpand(record, e)}
        />
      );
    }
    return null;
  };

  const columns = useBudgetDetailsColumns(
    cycle,
    data,
    dateRanges,
    formData,
    id,
    isSaveButtonDisabled,
    handleBlur,
    handleInputChange,
    showResetRowModal,
    permissions
  );
  return (
    <BudgetContext.Provider
      value={{ modalOpen, setModalOpen, selectedRow, setSelectRow }}
    >
      <BrakeDownModal data={data} dateRanges={dateRanges} cycle={cycle}  />
      {!isLoading && (
        <BudgetDetailsHeaderSection
          cycle={cycle}
          cycleCallBack={(value) => {
            setFormData(resetDateToZero(formData, value));
            setCycle(value);
            setIsSaveButtonDisabled(true);
          }}
          data={data}
          onReset={handleReset}
          permissions={permissions}
        />
      )}
      {!isLoading && <Table
        pagination={false}
        columns={columns}
        dataSource={dataSource}
        tableLayout="fixed"
        scroll={{ x: "max-content", y: 500 }}
        expandable={{
          expandedRowKeys: expandedKeys, 
          onExpand: handleExpand,
          rowExpandable,
          expandIcon: customExpandIcon,
        }}
      />}
      {permissions["write"] && type == "edit" &&
      <Flex justify="end" style={{ marginTop: token.marginLG }}>
        <Space>
          <Button
            type="primary"
            onClick={handleSubmit}
            disabled={isSaveButtonDisabled}
          >
            {t("budgets.show.save")}
          </Button>
          <Button
            onClick={() => {
              window.location.href = "/tenant/budgets";
            }}
          >
            {t("budgets.show.cancel")}
          </Button>
        </Space>
        {resetRowModal}
      </Flex>}
    </BudgetContext.Provider>
  );
};

export default BudgetDetails;
